/**
 * Definice vyhled�v�n�
 */
var products = new Bloodhound({

    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('nazev'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: 'ajax-search.json?type=product&query=%QUERY',
        wildcard: '%QUERY'
    }
});

var category = new Bloodhound({

    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('nazev'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: 'ajax-search.json?type=category&query=%QUERY',
        wildcard: '%QUERY'
    }
});

// Inicializace vyhled�vac�ho enginu
category.initialize();
products.initialize();

/**
 * Nastaven� typeheadu
 */
$typehead = $('#header-search').find('.input-block-level').typeahead(
    {
        highlight: true,
        hint: false,
        minLength: 2
    },
    {
        name: 'products',
        display: 'nazev',
        source: products,
        templates: {

            header: '<h2>Produkty</h2>',
            suggestion: function(row) {

                return '<div class="row fulltext-product">' +
                         '<div class="col-md-4"><img src="' + row.obrazek + '" /></div>' +
                         '<div class="col-md-20">' +
                            '<div>' + row.nazev + '</div>' +
                            '<div>' + row.cena + '</div>' +
                         '</div> ' +
                       '</div>';
            }
        },
        limit: 10
    },
    {
        name: 'category',
        display: 'nazev',
        source: category,
        templates: {

            header: '<h2>Kategorie</h2>',
            suggestion: function(row) {

                return '<div class="row fulltext-category">' +
                         '<div class="col-md-24">' + row.nazev + '</div>' +
                        '</div>';
            }
        },
        limit: 10
    }
);

$typehead.bind('typeahead:select', function(e, suggestion) {

    e.preventDefault();
    window.location = suggestion.link;
});